import revive_payload_client_Frd8gASYWa from "/var/lib/jenkins/workspace/PZO_master/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/var/lib/jenkins/workspace/PZO_master/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/var/lib/jenkins/workspace/PZO_master/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/var/lib/jenkins/workspace/PZO_master/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_aQoh9E5uaZ from "/var/lib/jenkins/workspace/PZO_master/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/lib/jenkins/workspace/PZO_master/client/switcher-public/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/var/lib/jenkins/workspace/PZO_master/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_kCUITUWXUN from "/var/lib/jenkins/workspace/PZO_master/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errorHandling_NIvX5cwfWH from "/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/plugins/errorHandling.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  chunk_reload_client_kCUITUWXUN,
  errorHandling_NIvX5cwfWH
]