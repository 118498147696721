import { default as declarationpe6ay2YCXVMeta } from "/var/lib/jenkins/workspace/PZO_master/client/switcher-public/pages/declaration.vue?macro=true";
import { default as devDomainoUHBqTz1M3Meta } from "/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/devDomain.vue?macro=true";
import { default as indexyg6c0s7JyNMeta } from "/var/lib/jenkins/workspace/PZO_master/client/switcher-public/pages/index.vue?macro=true";
import { default as notSupportedBrowserCyAG4i2ZVbMeta } from "/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/notSupportedBrowser.vue?macro=true";
export default [
  {
    name: declarationpe6ay2YCXVMeta?.name ?? "declaration",
    path: declarationpe6ay2YCXVMeta?.path ?? "/declaration",
    meta: declarationpe6ay2YCXVMeta || {},
    alias: declarationpe6ay2YCXVMeta?.alias || [],
    redirect: declarationpe6ay2YCXVMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/switcher-public/pages/declaration.vue").then(m => m.default || m)
  },
  {
    name: devDomainoUHBqTz1M3Meta?.name ?? "devDomain",
    path: devDomainoUHBqTz1M3Meta?.path ?? "/devDomain",
    meta: devDomainoUHBqTz1M3Meta || {},
    alias: devDomainoUHBqTz1M3Meta?.alias || [],
    redirect: devDomainoUHBqTz1M3Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/devDomain.vue").then(m => m.default || m)
  },
  {
    name: indexyg6c0s7JyNMeta?.name ?? "index",
    path: indexyg6c0s7JyNMeta?.path ?? "/",
    meta: indexyg6c0s7JyNMeta || {},
    alias: indexyg6c0s7JyNMeta?.alias || [],
    redirect: indexyg6c0s7JyNMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/switcher-public/pages/index.vue").then(m => m.default || m)
  },
  {
    name: notSupportedBrowserCyAG4i2ZVbMeta?.name ?? "notSupportedBrowser",
    path: notSupportedBrowserCyAG4i2ZVbMeta?.path ?? "/notSupportedBrowser",
    meta: notSupportedBrowserCyAG4i2ZVbMeta || {},
    alias: notSupportedBrowserCyAG4i2ZVbMeta?.alias || [],
    redirect: notSupportedBrowserCyAG4i2ZVbMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/notSupportedBrowser.vue").then(m => m.default || m)
  }
]